import { Injectable } from '@angular/core';
import { GLOBAL } from '../shared/parametrosGlobales';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService } from './config.service';

@Injectable()
export class GlobalService {
    public url: string = ConfigService.settings.env;
    public path: string;

    constructor(public _http: HttpClient,private sanitizer:DomSanitizer ) { }

    public findListaDocumento() {
        this.path = GLOBAL.global.getTipoDocumento;
        return this._http.get(this.url + this.path, {}).toPromise();
    }

    public findTiposEmpresa() {
        this.path = GLOBAL.global.getTipoEmpresa;
        return this._http.get(this.url + this.path, {}).toPromise();
    }

    public findListaEstado() {
        this.path = GLOBAL.global.getDepartamentos;
        return this._http.get(this.url + this.path, {}).toPromise();
    }

    public findListaCiudad(idDepartamento: string) {
        this.path = GLOBAL.global.getCiudadesPorDepartamento;
        return this._http.get(this.url + this.path + idDepartamento, {}).toPromise();
    }

    public findDireccion(cep: string) {
        this.path = GLOBAL.global.getDireccionPorCEP;
        return this._http.get(this.url + this.path + cep, {}).toPromise();
    }

    public findCiudadesConDepartamentos() {
        this.path = GLOBAL.global.getCiudadesConDepartamentos;
        return this._http.get(this.url + this.path, {}).toPromise();
    }

    public findDireccionByApiCorreios(cep: string) : Promise<any> {
        this.path = GLOBAL.correios.getDireccionByApiCorreios;
        return this._http.get(this.url + this.path + cep, {}).toPromise();
    }

    public findDepartamentoBySigla(sigla: string) : Promise<any> {
        this.path = GLOBAL.global.getDepartamentoBySigla;
        return this._http.get(this.url + this.path + sigla, {}).toPromise();
    }

    /*public findValorParametroById(idParametro: number) : Promise<any> {
        this.path = GLOBAL.global.getValorParametroById;
        return this._http.get(this.url + this.path + idParametro, {}).toPromise();
    }*/

    public findValorParametroById(idParametro: string) : Promise<any> {
        this.path = GLOBAL.global.getValorParametro;
        return this._http.get(this.url + this.path + idParametro, {}).toPromise();
    }

}