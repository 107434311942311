import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  PARAMETRO_CREDENCIAMENTO_EMPRESA_PDF = "eyJhbGciOiJSUzI1NiJ9.eyJqdGkiOiIzNjExIn0.Evee2cz-W7-fOn9uMVDAZSqY82TVaOogcj-5Loo8L6nuCMWb3Izkm9cmvPcOx6MYI_62Zl7VoMRcO9jFXlk3ULC7dXWhMUtWTP-T2GHcBtMh8C-u8kIo-VMGuWsGGyGkqTEgAA4P6CFTxrVfwCv52tQU8ZephLcl_wVwY-vcbeNjuqJU58TdTVvZbDSm2t8uRum0x07mrgU1Goe1cDkTXMzsnS8NKBHTov09fO2VpTWF0xw8VjmhxyEDDMVFxakGejpG2BxKU4NuU1bLUoEv8cZ1BxBYmSlYhk6CeUR0nfzykp1YXR6H2JjOSFxCbaJb4gJEoX2nrARZEiIUq1zD_A";
  PARAMETRO_REGULAMENTO_PIEZA_PDF = "eyJhbGciOiJSUzI1NiJ9.eyJqdGkiOiIzNjEyIn0.N1TQOb53p8Xyjp-3l6tN1_51Aox7s2gB3uob7Y_UkLMo3fKqMQcOS-MnwCxpuHOU0CcaTaIdDddueUEHBCirsCAu9xLKtO-voBpx4Vh6d1_aoIqvqq-2Vz8kdTgJ-a4Nshlh2OpendW7GYKVkrAHoSR_d_7fRif-juIh8iauF5cboqPbeImC10c0jjYVNowZtRGEoYQOlHYl5JfcnzV5W1CfswKQuYSGrAR_6xs3NOzwyLzZ8WEQZuzBiXuLj3RxAZQjecJqq9roAghqQHQeMRbQsZ97aqRO2-ddQOn1FJ2cXSB6QklMm4fJ1UAdA0u20py10cXfxiuCaBfBWSG2qA";
  PARAMETRO_CARTILHA_CREDENCIAMENTO_PDF = "eyJhbGciOiJSUzI1NiJ9.eyJqdGkiOiIzNjEzIn0.MMlLhDL6wB-PbZTiqv2O1xCwKxAMj59gJXabIJy2TN0M_k5xAo224eLqn9_-kfXPolbYky_RepF3SA9VEKjoVFV116IRr7lz7RSqo_CIi7Pse6L2NImGBU2lQWeALV9uenfxuWxnCJMndFe3ryPRVRYwi5rUNwyyF2uYIlUJzbpkfOuky2FKA-0H0QLHVRojq5ozJFmGojqZM_igBrlHXlWZYwR7iECJgpDSeCS4a-cW2tTeZaIXsLR5b5BuxlgBpnN1tCoaGK7aGrpgvLsk0smqV9SxAzWQzsZPhCLlgVqhyv-jBq_R368yCCMAp75YSahHkmyyKbz8lB5buriq_A"; 
  
  urlCredenciamento: string;
  urlRegulamento: string;
  urlCartilha: string;

  constructor(private _globalService: GlobalService) { }

  ngOnInit() {
    this._globalService.findValorParametroById(this.PARAMETRO_CREDENCIAMENTO_EMPRESA_PDF).then((data: any) => {      
      if(data != null){
        this.urlCredenciamento = data;
      }      
    })
    this._globalService.findValorParametroById(this.PARAMETRO_REGULAMENTO_PIEZA_PDF).then((data: any) => {
      if(data != null){
        this.urlRegulamento = data;
      }
    })
    this._globalService.findValorParametroById(this.PARAMETRO_CARTILHA_CREDENCIAMENTO_PDF).then((data: any) => {
      if(data != null){
        this.urlCartilha = data;
      }
    })
  }

}
